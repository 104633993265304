import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { ReferralProgramComponent } from './referral-program.component';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideRouter([
      {
        path: '',
        component: ReferralProgramComponent,
      },
    ]),
  ],
})
export class ReferralModule {}
