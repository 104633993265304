<div class="referral-activity">
  <div class="referral-activity__card">
    <div>
      <p class="body1--regular white">{{ 'REFERRAL_PROGRAM.ACTIVITY.REGISTERED' | translate }}</p>
      <p class="heading1--bold white">{{ referralProgram.registeredThroughYou }}</p>
    </div>
    <img src="{{ assetsPath + 'personalcard.svg' }}" />
  </div>
  <div class="referral-activity__card">
    <div>
      <p class="body1--regular white">{{ 'REFERRAL_PROGRAM.ACTIVITY.ACHIEVED' | translate }}</p>
      <p class="heading1--bold white">{{ referralProgram.achievedMerchantsCount }}</p>
    </div>
    <img src="{{ assetsPath + 'chart-success.svg' }}" />
  </div>
  <div class="referral-activity__card">
    <div>
      <p class="body1--regular white">{{ 'REFERRAL_PROGRAM.ACTIVITY.PENDING' | translate }}</p>
      <p class="heading1--bold white">{{ referralProgram.pendingProfit }}</p>
    </div>
    <img src="{{ assetsPath + 'money-send.svg' }}" />
  </div>
  <div class="referral-activity__card">
    <div>
      <p class="body1--regular white">{{ 'REFERRAL_PROGRAM.ACTIVITY.TOTAL' | translate }}</p>
      <p class="heading1--bold white">{{ referralProgram.totalProfit }}</p>
    </div>
    <img src="{{ assetsPath + 'wallet-money.svg' }}" />
  </div>
</div>
