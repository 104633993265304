export interface ReferralsProgramModel {
  totalProfit: number;
  pendingProfit: number;
  registeredThroughYou: number;
  achievedMerchantsCount: number;
  firstTarget: number;
  secondTarget: number;
  referredMerchants: ReferredMerchantsModel[];
}

export interface ReferredMerchantsModel {
  taagerId: string;
  name: string;
  joinDate: string;
  dateOfCompletion: string;
  deliveredOrders: number;
  dueProfit: number;
  profitStatus: string;
  targetStatus: TargetStatusType;
}

export enum TargetStatusType {
  ACHIEVED = 'ACHIEVED',
  NOT_ACHIEVED = 'NOT_ACHIEVED',
  IN_PROGRESS = 'IN_PROGRESS',
}
