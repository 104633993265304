import { DatePipe, NgClass, NgFor, NgIf, registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {
  ReferredMerchantsModel,
  TargetStatusType,
} from 'src/app/core/domain/referrals-program.model';
import {
  ReferredMerchantProfitStatuses,
  REFERRED_MERCHANT_PROFIT_STATUS_MAPPER,
} from 'src/app/data/repositories/referral-program/entities/mappers/referred-merchant-profit-status.mapper';
import { BaseComponent } from '../../base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { monthNameDayYearFormat } from '../../shared/constants';
import { ReferralProgramTablePresenter } from './presenter/referral-program-table.presenter';
import { ReferralProgramTableSideEffect } from './presenter/referral-program-table.sideEffects';
import { ReferralProgramTableViewEvents } from './presenter/referral-program-table.viewEvents';
import { ReferralProgramTableViewState } from './presenter/referral-program-table.viewState';

registerLocaleData(localeAr, 'ar');
@Component({
  selector: 'app-referral-program-table',
  standalone: true,
  imports: [
    TranslateModule,
    NgFor,
    NgIf,
    LoaderComponent,
    DatePipe,
    MatProgressSpinnerModule,
    NgClass,
    PaginationModule,
    FormsModule,
  ],
  templateUrl: './referral-program-table.component.html',
  styleUrls: ['./referral-program-table.component.scss'],
  providers: [ReferralProgramTablePresenter],
})
export class ReferralProgramTableComponent extends BaseComponent<
  ReferralProgramTablePresenter,
  ReferralProgramTableViewState,
  ReferralProgramTableViewEvents,
  ReferralProgramTableSideEffect
> {
  public assetsPath = 'assets/img/referral-program/';

  @Input() referralTableData: ReferredMerchantsModel[];

  @Input() isLoading: boolean;

  @Input() pageSize: number;

  @Input() firstTarget: number;

  @Input() secondTarget: number;

  @Output() clickedPage: EventEmitter<number> = new EventEmitter();

  public dateFormat = monthNameDayYearFormat;

  public ReferredMerchantProfitStatuses = ReferredMerchantProfitStatuses;

  public ReferredMerchantTargetStatuses = TargetStatusType;

  public statusMapper = REFERRED_MERCHANT_PROFIT_STATUS_MAPPER;

  public presenter: ReferralProgramTablePresenter = inject(ReferralProgramTablePresenter);

  onSideEffect(sideEffect: ReferralProgramTableSideEffect): void {
    switch (sideEffect.type) {
      case 'LoadPaginatedList': {
        this.clickedPage.emit(sideEffect.paginationClicked.page);
        break;
      }
    }
  }
}
