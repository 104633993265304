import { Clipboard } from '@angular/cdk/clipboard';
import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base/base.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ReferralProgramPresenter } from './presenter/referral-program.presenter';
import { ReferralProgramSideEffect } from './presenter/referral-program.sideEffects';
import { ReferralProgramViewEvents } from './presenter/referral-program.viewEvents';
import { ReferralProgramViewState } from './presenter/referral-program.viewState';
import { ReferralActivityComponent } from './referral-activity/referral-activity.component';
import { ReferralProgramTableComponent } from './referral-program-table/referral-program-table.component';

@Component({
  selector: 'app-referral-program',
  standalone: true,
  imports: [
    TranslateModule,
    NgFor,
    LoaderComponent,
    NgIf,
    ReferralActivityComponent,
    ReferralProgramTableComponent,
  ],
  templateUrl: './referral-program.component.html',
  styleUrls: ['./referral-program.component.scss'],
  providers: [ReferralProgramPresenter],
})
export class ReferralProgramComponent extends BaseComponent<
  ReferralProgramPresenter,
  ReferralProgramViewState,
  ReferralProgramViewEvents,
  ReferralProgramSideEffect
> {
  public assetsPath = 'assets/img/referral-program/';

  public presenter: ReferralProgramPresenter = inject(ReferralProgramPresenter);

  constructor(
    private _clipboard: Clipboard,
    private _toast: ToastrService,
    private _translateService: TranslateService,
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  onSideEffect(sideEffect: ReferralProgramSideEffect): void {
    switch (sideEffect.type) {
      case 'CopyReferralCode': {
        this._clipboard.copy(this.viewState.referralCode);
        this._toast.info(this._translateService.instant('REFERRAL_PROGRAM.REFERRAL_CODE_COPIED'));
        break;
      }
      case 'CopyReferralLink': {
        this._clipboard.copy(
          `https://taager.com/sa/auth/signup?referralCode=${this.viewState.referralCode}&src=referral-link`,
        );
        this._toast.info(this._translateService.instant('REFERRAL_PROGRAM.REFERRAL_LINK_COPIED'));
        break;
      }
    }
  }
}
