import { Injectable } from '@angular/core';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ReferralProgramTableSideEffect } from './referral-program-table.sideEffects';
import { ReferralProgramTableViewEvents } from './referral-program-table.viewEvents';
import { ReferralProgramTableViewState } from './referral-program-table.viewState';

@Injectable()
export class ReferralProgramTablePresenter extends BasePresenter<
  ReferralProgramTableViewState,
  ReferralProgramTableViewEvents,
  ReferralProgramTableSideEffect
> {
  protected defaultViewState(): ReferralProgramTableViewState {
    return {
      maxPaginationSize: 6,
      currentPage: 1,
    };
  }

  protected onViewEvent(event: ReferralProgramTableViewEvents): void {
    switch (event.type) {
      case 'ClickTablePagination': {
        this.emitSideEffect({
          type: 'LoadPaginatedList',
          paginationClicked: event.paginationClicked,
        });
        this.updateViewState({
          ...this.viewState,
          currentPage: event.paginationClicked.page,
        });
        break;
      }
    }
  }
}
