<div class="referral-container">
  <div class="referral-container__top">
    <div class="referral-container__top__right">
      <p class="heading1--bold white">
        {{ 'REFERRAL_PROGRAM.INVITE' | translate }}
      </p>
      <p class="body2--regular white">
        {{ 'REFERRAL_PROGRAM.EARN' | translate }}
      </p>
    </div>
    <div class="referral-container__top__left">
      <a
        target="_blank"
        [href]="viewState.whatsAppLink"
        (click)="
      presenter.emitViewEvent({
        type: 'ClickWhatsAppButton',
      })
    "
      >
        <img class="clickable" src="{{ assetsPath + 'whatsapp.svg' }}" />
      </a>
      <button
        class="referral-button referral-button__code"
        (click)="
      presenter.emitViewEvent({
        type: 'ClickCopyReferralCode',
      })
    "
      >
        <div class="same-row">
          <p class="body1--bold">{{ 'REFERRAL_PROGRAM.COPY_CODE' | translate }}</p>
          <img src="{{ assetsPath + 'white-copy.svg' }}" />
        </div>
        <loader
          size="md"
          *ngIf="viewState.isLoading"
          [loading]="viewState.isLoading"
          class="loader"
        ></loader>
        <p class="body1--bold" *ngIf="!viewState.isLoading">{{ viewState.referralCode }}</p>
      </button>
      <button
        class="referral-button referral-button__link"
        (click)="
      presenter.emitViewEvent({
        type: 'ClickCopyReferralLink',
      })
    "
      >
        <p class="body1--bold">{{ 'REFERRAL_PROGRAM.COPY_LINK' | translate }}</p>
        <img src="{{ assetsPath + 'gold-copy.svg' }}" />
      </button>
    </div>
  </div>
  <loader
    size="md"
    *ngIf="viewState.isLoading"
    [loading]="viewState.isLoading"
    class="loader"
  ></loader>
  <div class="referral-container__step" *ngIf="!viewState.isLoading">
    <div class="referral-container__step__card" *ngFor="let step of viewState.referralSteps">
      <img src="{{ step.icon }}" />
      <div>
        <p class="caption2--regular grey">
          {{ step.stepOrder | translate }}
        </p>
        <p class="caption1--bold white">
          {{ step.title | translate }}
        </p>
        <p class="caption2--regular white">
          {{ step.text | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="referral-container__activity">
    <app-referral-activity [referralProgram]="viewState.referralProgram"></app-referral-activity>
  </div>
  <div class="referral-container__bottom">
    <div class="referral-container__bottom__warning">
      <img src="{{ assetsPath + 'danger.svg' }}" />
      <div>
        <p class="caption1--regular white">{{ 'REFERRAL_PROGRAM.ACTIVITY.WARNING' | translate }}</p>
        <p class="caption1--regular white">
          {{ 'REFERRAL_PROGRAM.ACTIVITY.TERMINATED' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<app-referral-program-table
  [referralTableData]="viewState.referralProgram.referredMerchants"
  [isLoading]="viewState.isLoading"
  [pageSize]="viewState.pageSize"
  [firstTarget]="viewState.firstTarget"
  [secondTarget]="viewState.secondTarget"
  (clickedPage)="presenter.emitViewEvent({ type: 'PaginationClicked', selectedPage: $event })"
></app-referral-program-table>
