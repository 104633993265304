import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReferralsProgramModel } from 'src/app/core/domain/referrals-program.model';

@Component({
  selector: 'app-referral-activity',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './referral-activity.component.html',
  styleUrls: ['./referral-activity.component.scss'],
})
export class ReferralActivityComponent {
  @Input() referralProgram: ReferralsProgramModel;

  public assetsPath = 'assets/img/referral-program/';
}
