<div class="referral-table-container">
  <p class="heading2--bold content-main-color">
    {{ 'REFERRAL_PROGRAM.TABLE.MERCHANTS_PROGRESS' | translate }}
  </p>
  <loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader centered"></loader>
  <table class="referral-table" *ngIf="!isLoading">
    <tr class="referral-table__row">
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_NAME' | translate }}
      </th>
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_JOINING_DATE' | translate }}
      </th>
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_REMAINING_DATE' | translate }}
      </th>
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_FIRST_MONTH_PROGRESS' | translate }}
      </th>
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_DUE_PROFIT' | translate }}
      </th>
      <th class="referral-table__header content-medium-color caption1--medium">
        {{ 'REFERRAL_PROGRAM.TABLE.MERCHANT_PROFIT_STATUS' | translate }}
      </th>
    </tr>
    <ng-container *ngIf="!isLoading">
      <tr
        class="referral-table__row"
        *ngFor="let data of referralTableData; let index = index"
        [ngClass]="{
          'referral-table__row__last-row': index === referralTableData?.length - 1,
        }"
      >
        <td class="referral-table__content caption1--bold">
          <p class="caption1--regular content-main-color">{{ data.name }}</p>
          <p class="caption2--regular content-medium-color">{{ data.taagerId }}</p>
        </td>
        <td class="referral-table__content">
          <p class="caption1--regular content-main-color">
            {{ data.joinDate | date : dateFormat : undefined : (isLTR ? 'en' : 'ar') }}
          </p>
        </td>
        <td class="referral-table__content">
          <div class="referral-table__content__same-row">
            <i
              class="icon icon-timer"
              [ngClass]="{
                'gray-bg': data.targetStatus === ReferredMerchantTargetStatuses.IN_PROGRESS,
                'red-bg': data.targetStatus === ReferredMerchantTargetStatuses.NOT_ACHIEVED,
                'green-bg': data.targetStatus === ReferredMerchantTargetStatuses.ACHIEVED,
            }"
            ></i>
            <p
              *ngIf="data.dateOfCompletion !== 'expired'"
              class="caption1--regular content-main-color"
            >
              {{ data.dateOfCompletion.split(' ')[0] }}
              {{ 'REFERRAL_PROGRAM.TABLE.DAYS' | translate }} :
              {{ data.dateOfCompletion.split(' ')[1] }}
              {{ 'REFERRAL_PROGRAM.TABLE.HOURS' | translate }}
            </p>
            <p
              class="caption1--regular content-main-color"
              *ngIf="data.dateOfCompletion === 'expired' && data.deliveredOrders >= firstTarget"
            >
              {{ 'REFERRAL_PROGRAM.TABLE.ACHIEVED' | translate }}
            </p>
            <p
              class="caption1--regular content-main-color"
              *ngIf="data.dateOfCompletion === 'expired' && data.deliveredOrders < firstTarget"
            >
              {{ 'REFERRAL_PROGRAM.TABLE.NOT_ACHIEVED' | translate }}
            </p>
          </div>
        </td>
        <td class="referral-table__content">
          <div class="referral-table__content__same-row">
            <div
              class="table-loader"
              *ngIf="data.dateOfCompletion !== 'expired' && data.deliveredOrders < secondTarget"
            >
              <div class="back-spinner">
                <mat-progress-spinner diameter="25" strokeWidth="3" mode="determinate" value="100">
                </mat-progress-spinner>
              </div>
              <mat-progress-spinner
                diameter="25"
                strokeWidth="3"
                mode="determinate"
                value="{{ data.deliveredOrders * 10 }}"
              >
              </mat-progress-spinner>
            </div>
            <img
              src="{{ assetsPath + 'false.svg' }}"
              *ngIf="data.dateOfCompletion === 'expired' && data.deliveredOrders < firstTarget"
            />
            <img
              src="{{ assetsPath + 'true.svg' }}"
              *ngIf="
                (data.dateOfCompletion !== 'expired' && data.deliveredOrders >= secondTarget) ||
                (data.dateOfCompletion === 'expired' && data.deliveredOrders >= firstTarget)
              "
            />
            <p class="caption1--regular content-main-color">{{ data.deliveredOrders }}</p>
            <p class="caption1--regular content-main-color">
              {{ 'REFERRAL_PROGRAM.TABLE.DELIVERED_ORDERS' | translate }}
            </p>
          </div>
        </td>
        <td class="referral-table__content">
          <div class="referral-table__content__same-row">
            <img src="{{ assetsPath + 'money.svg' }}" />
            <p class="caption1--bold content-main-color">{{ data.dueProfit }}</p>
            <p class="caption1--bold content-main-color">
              {{ 'REFERRAL_PROGRAM.TABLE.SAR' | translate }}
            </p>
          </div>
        </td>
        <td class="referral-table__content">
          <div class="referral-table__content__same-row">
            <i
              class="icon icon-wallet-money"
              [ngClass]="{
                gray:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PENDING_ACHIEVEMENT),
                red:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.NO_PROFIT_DESERVED),
                green:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PROFIT_RECEIVED),
                orange:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PROFIT_IN_PROGRESS)
              }"
            ></i>
            <p
              class="caption1--regular"
              [ngClass]="{
                gray:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PENDING_ACHIEVEMENT),
                red:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.NO_PROFIT_DESERVED),
                green:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PROFIT_RECEIVED),
                orange:
                  data.profitStatus ===
                  statusMapper.get(ReferredMerchantProfitStatuses.PROFIT_IN_PROGRESS)
              }"
            >
              {{ data.profitStatus | translate }}
            </p>
          </div>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="referralTableData?.length > secondTarget">
      <td colspan="100">
        <pagination
          [totalItems]="referralTableData?.length"
          [itemsPerPage]="pageSize"
          [maxSize]="viewState.maxPaginationSize"
          [(ngModel)]="viewState.currentPage"
          (pageChanged)="
            presenter.emitViewEvent({ type: 'ClickTablePagination', paginationClicked: $event })
          "
          previousText="<"
          nextText=">"
        >
        </pagination>
      </td>
    </tr>
    <tr>
      <td *ngIf="referralTableData?.length === 0" colspan="100">
        <div class="empty-table">
          <img src="{{ assetsPath + 'empty.svg' }}" />
          <p class="body2--bold content-main-color">
            {{ 'REFERRAL_PROGRAM.TABLE.EMPTY' | translate }}
          </p>
        </div>
      </td>
    </tr>
  </table>
</div>
