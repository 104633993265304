import { inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { ReferralsProgramModel } from 'src/app/core/domain/referrals-program.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetReferralStepsDataUseCase } from 'src/app/core/usecases/referral-program/get-referral-steps-data.usecase';
import { GetReferralsProgramUseCase } from 'src/app/core/usecases/referral-program/get-referrals-program.usecase';
import { GetUserProfileUseCase } from 'src/app/core/usecases/user/get-user-profile.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { ReferralProgramSideEffect } from './referral-program.sideEffects';
import { ReferralProgramViewEvents } from './referral-program.viewEvents';
import { ReferralProgramViewState } from './referral-program.viewState';

@Injectable()
export class ReferralProgramPresenter extends BasePresenter<
  ReferralProgramViewState,
  ReferralProgramViewEvents,
  ReferralProgramSideEffect
> {
  private _getReferralStepsDataUseCase = inject(GetReferralStepsDataUseCase);

  private _getUserProfileUseCase = inject(GetUserProfileUseCase);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  private _getReferralsProgramUseCase = inject(GetReferralsProgramUseCase);

  private _translateService = inject(TranslateService);

  private _toastService = inject(ToastrService);

  protected defaultViewState(): ReferralProgramViewState {
    return {
      isLoading: false,
      referralSteps: [],
      referralCode: '',
      whatsAppLink: '',
      referralProgram: {} as ReferralsProgramModel,
      isLTR: false,
      firstTarget: 5,
      secondTarget: 10,
      page: 1,
      pageSize: 10,
    };
  }

  getRemainingTime(dateOfCompletion: string): string {
    const now = new Date();
    const completionDate = new Date(dateOfCompletion);
    const diffDays = Math.floor((completionDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(
      ((completionDate.getTime() - now.getTime()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    if (diffDays > 0 || diffHours > 0) {
      return `${diffDays} ${diffHours} `;
    }
    return 'expired';
  }

  fetchingReferralData(): void {
    combineLatest([
      this._getUserProfileUseCase.execute(),
      this._getReferralStepsDataUseCase.execute(),
      this._getReferralsProgramUseCase.execute({
        page: this.viewState.page,
        pageSize: this.viewState.pageSize,
      }),
    ]).subscribe({
      next: ([{ data }, referralSteps, referralProgram]) => {
        const mappedReferredMerchantsData = referralProgram.referredMerchants.map((merchant) => ({
          ...merchant,
          dateOfCompletion: this.getRemainingTime(merchant.dateOfCompletion),
        }));
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
          referralCode: data.referralCode!,
          referralSteps,
          whatsAppLink: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `تبدأ تجارتك الإلكترونية إزاي؟ حد من أصحابك رشحك في تاجر 🤝 منصة للتجارة الإلكترونية في أكتر من 3 دول، تحقق من خلالها أرباح بعملات مختلفة 🚀 استخدم الكود ${data.referralCode} خلال إنشاء الحساب أو افتح اللينك حالًا و اقبل دعوتك  ✅ https://taager.com/sa/auth/signup?referralCode=${data.referralCode}&src=whatsapp`,
          )}`,
          firstTarget: referralProgram.firstTarget,
          secondTarget: referralProgram.secondTarget,
          referralProgram: {
            ...referralProgram,
            referredMerchants: mappedReferredMerchantsData,
          },
        });
      },
      error: () => {
        this._toastService.error(this._translateService.instant('ERRORS.SUPPORT_TEAM'));
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
        });
      },
    });
  }

  protected onViewEvent(event: ReferralProgramViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          isLoading: true,
        });
        this.add(
          this._translateService.onLangChange.subscribe({
            next: (langEvent: LangChangeEvent) => {
              this.updateViewState({
                ...this.viewState,
                isLTR: langEvent.lang === ENGLISH_LANGUAGE,
              });
            },
          }),
        );
        this._logMixpanelEventUseCase.execute({ eventName: 'referral_program_page_loaded' });
        this.fetchingReferralData();
        break;
      }
      case 'ClickWhatsAppButton': {
        this._logMixpanelEventUseCase.execute({ eventName: 'referral_program_whatsapp_clicked' });
        this.emitSideEffect({
          type: 'OpenWhatsApp',
        });
        break;
      }
      case 'ClickCopyReferralLink': {
        this._logMixpanelEventUseCase.execute({ eventName: 'referral_program_copy_link_clicked' });
        this.emitSideEffect({
          type: 'CopyReferralLink',
        });
        break;
      }
      case 'ClickCopyReferralCode': {
        this._logMixpanelEventUseCase.execute({ eventName: 'referral_program_copy_code_clicked' });
        this.emitSideEffect({
          type: 'CopyReferralCode',
        });
        break;
      }
      case 'PaginationClicked': {
        this.emitSideEffect({
          type: 'TablePaginationClicked',
          selectedPage: event.selectedPage,
        });
        this.updateViewState({
          ...this.viewState,
          page: event.selectedPage,
        });
        this.fetchingReferralData();
        break;
      }
    }
  }
}
